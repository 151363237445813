





















import Vue, { PropType } from 'vue'
import { deleteTariff } from '~/api/Remittances/remittances.api'
import CreateDialog from '~/components/CreateDialog.vue'
import IconButton from '~/components/IconButton.vue'
import { Tariff } from '~/utils/interfaces/remittance.interface'
export default Vue.extend({
  components: { IconButton, CreateDialog },
  props: {
    item: {
      type: Object as PropType<Tariff>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      model: false,
    }
  },
  methods: {
    async onSuccess() {
      this.loading = true
      try {
        await deleteTariff(this.item.uuid)
        this.$emit('refresh')
        this.model = false
      } finally {
        this.loading = false
      }
    },
  },
})
