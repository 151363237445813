





















import Vue from 'vue'
export default Vue.extend({
  props: {
    fab: Boolean,
    icon: { type: String, default: 'mdi-pencil' },
  },
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark
    },
  },
})
